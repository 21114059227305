<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          {{ $t("edittransfer") }}
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- -->
        <div class="row text-sm-start d-flex justify-content-center">
          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label">{{
              $t("documentnumber")
            }}</label>
            <input
              v-model="form.transaction_number"
              disabled
              class="form-control mb-4"
            />
          </div>
          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label">{{
              $t("documentdate")
            }}</label>
            <input
              v-model="form.updatedAt"
              disabled
              class="form-control mb-4"
            />
          </div>
        </div>

        <div class="row text-sm-start d-flex justify-content-center mb-4">
          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label required">{{
              $t("transferfrom")
            }}</label>
            <select class="form-select" v-model="form.fromWarehouseId" disabled>
              <option v-for="item in warehouses" :key="item" :value="item.id">
                {{ item.warehouse_code }} | {{ item.warehouse_short_name }} |
                {{ item.warehouse_full_name }}
              </option>
            </select>
          </div>

          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label required">{{
              $t("transfertowarehouse")
            }}</label>
            <select class="form-select" v-model="form.toWarehouseId" disabled>
              <option v-for="item in warehouses" :key="item" :value="item.id">
                {{ item.warehouse_code }} | {{ item.warehouse_short_name }} |
                {{ item.warehouse_full_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row text-sm-start d-flex justify-content-center mb-4">
          <div class="col-sm-10">
            <label for="selectMainProduct" class="form-label">{{
              $t("annotation")
            }}</label>
            <input v-model="form.remark" class="form-control mb-4" />
          </div>
        </div>
        <div class="row text-sm-start d-flex justify-content-center mb-4">
          <div class="col-sm-10">
            <label for="selectMainProduct" class="form-label required"
              >{{ $t("status") }}
            </label>
            <select
              :style="
                isSubmit
                  ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                  : ''
              "
              class="form-select"
              v-model="form.status"
            >
              <!-- @change="updatestatus(item)" -->
              <option
                :disabled="
                  form.status == 'รับสินค้าแล้ว' || form.status == 'รอส่งของ'
                "
                value="รอจัดของ"
              >
                รอจัดของ
              </option>
              <option
                :disabled="form.status == 'รับสินค้าแล้ว'"
                value="รอส่งของ"
              >
                รอส่งของ
              </option>
              <option value="รับสินค้าแล้ว">รับสินค้าแล้ว</option>
            </select>
          </div>
        </div>

        <!-- <div class="row text-end">
          <div class="col-sm-12">
            <button
              type="button"
              class="btn btn-sm btn-light btn-success"
              style="font-size: 14px; font-weight: bold"
              @click="addItem()"
            >
              + เพิ่ม
            </button>
            <AddItem
              :dialogAddItem="dialogAddItem"
              :warehouseId="form.fromWarehouseId"
              @addProduct="addProduct"
              @closeDialogAddItem="closeDialogAddItem"
            />
          </div>
        </div> -->

        <div class="table-responsive" style="padding-top: 3px">
          <table
            class="table table-hover table-rounded table-striped border gy-7 gs-7"
          >
            <thead>
              <tr
                style="width: 100%"
                class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
              >
                <th>#</th>
                <th>Item code</th>
                <th>Item name</th>
                <th>Request Amount</th>
                <th style="width: 20%">{{ $t("amount") }}</th>
                <!-- <th></th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-if="dataItemsShow.length < 1">
                <td class="text-center" colspan="6">ไม่มีข้อมูล</td>
              </tr>
              <tr v-for="(item, index) in dataItemsShow" :key="index">
                <td></td>
                <td>
                  <!-- {{ item.product ? (item.product.code ? item.code : "") : "" }} -->
                  {{ item.code ? item.code : item.product.code }}
                </td>
                <td>
                  <!-- {{ item.product ? (item.product.name ? item.name : "") : "" }} -->
                  {{ item.name ? item.name : item.product.name }}
                </td>
                <td>{{ item.requestStockAmt }} {{ item.productUnit.name }}</td>
                <td>
                  <div class="input-group">
                    <input
                      :disabled="form.status !== 'รับสินค้าแล้ว'"
                      type="number"
                      class="form-control text-end rounded-3"
                      v-model="item.stockAmt"
                      @blur="addQtyByIndex(index, item.stockAmt)"
                    />
                    <div
                      class="input-group-append d-flex justify-content-center align-items-center ms-2"
                    >
                      <span>{{ item.productUnit.name }}</span>
                    </div>
                  </div>
                </td>
                <td></td>
                <!-- <td>
                  <div>
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success me-sm-2 p-0"
                      @click="goToEdit(item.productId)"
                    >
                      <i class="bi bi-pencil-square"></i>
                    </button>
                  </div>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>

        <!-- <div class="row d-flex justify-content-center mt-4">
          <div class="col-sm-8">
            <label for="selectMainProduct" class="form-label">หมายเหตุ</label>
            <input class="form-control mb-4" />
            <label for="selectMainProduct" class="form-label"
              >โอนเข้าคลัง</label
            >
            <input class="form-control mb-4" />
          </div>

          <div class="col-sm-4">
            <label for="selectMainProduct" class="form-label"
              >มูลค่ารวม(ก่อนVAT)</label
            >
            <input disabled class="form-control mb-4" />

            <label for="selectMainProduct" class="form-label">ส่วนลดรวม</label>
            <input disabled class="form-control mb-4" />

            <label for="selectMainProduct" class="form-label">VAT</label>
            <input disabled class="form-control mb-4" />

            <label for="selectMainProduct" class="form-label"
              >มูลค่าสุทธิ</label
            >
            <input disabled class="form-control" />
          </div>
        </div> -->
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-light btn-primary me-2"
            style="font-size: 14px; font-weight: bold"
            @click="submittransaction()"
          >
            {{ $t("save") }}
          </button>
          <button
            type="button"
            class="btn btn-sm btn-light"
            style="font-size: 14px; font-weight: bold"
            @click="cancel"
          >
            {{ $t("cancel") }}
          </button>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import AddItem from "./components/dialogAddItem.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";
import transferApi from "@/api/transfer/";
import moment from "moment";

export default {
  components: {
    AddItem,
  },
  setup() {
    document.title = "CHOMTHANA | สร้างใบโอนสินค้า";
  },
  data: () => ({
    searchProduct: "",
    isSubmit: false,
    isValidRetailPrice: false,
    dialogAddItem: false,
    form: {
      transaction_number: "",
      fromWarehouseId: 0,
      toWarehouseId: 0,
      updatedAt: "",
      remark: "",
      status: "",
    },
    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
    warehouses: [],
    dataItemsShow: [],
  }),

  created() {
    let id = this.$route.query.id;
    this.getAllWarehouse();
    this.getTransaction(id);
  },
  methods: {
    addQtyByIndex(index, stockAmt) {
      this.dataItemsShow[index] = {
        ...this.dataItemsShow[index],
        stockAmt: stockAmt,
      };
      // this.sumPayout();
    },
    goToEdit(id) {
      let routeData = this.$router.resolve({
        name: "WH4-edit",
        query: {
          id: id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    addProduct(item) {
      console.log("addProduct", item);
      this.dataItemsShow.push(item);
    },
    async getAllWarehouse() {
      let responseData = [];
      try {
        responseData = await whApi.warehouse.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.warehouses = responseData.data;
      }
    },
    async getTransaction(id) {
      let responseData = [];
      try {
        responseData = await transferApi.transfer.getOne(id);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        let result = responseData.data;
        this.form.id = result.id;
        this.form.transaction_number = result.transaction_number;
        this.form.fromWarehouseId = result.form_warehouse_id;
        this.form.toWarehouseId = result.to_warehouse_id;
        this.form.remark = result.remark;
        this.form.status = result.status;
        this.form.updatedAt = moment(result.updatedAt).format(
          "DD/MM/YYYY | HH:mm น."
        );
        this.dataItemsShow = result.productTransfers;
        console.log("this.dataItemsShow", this.dataItemsShow);
      }
    },
    // async cutStock(item) {
    //   let getone = [];
    //   getone = await transferApi.transfer.getOne(item.id);
    //   console.log("getone", getone);
    //   // transaction_number
    //   // form_warehouse_id
    //   // productTransfers
    //   // to_warehouse_id

    //   let responseData = [];
    //   let body = {
    //     fromWarehouseId: getone.data.form_warehouse_id,
    //     toWarehouseId: getone.data.to_warehouse_id,
    //     products: getone.data.productTransfers,
    //   };

    //   try {
    //     responseData = await whApi.warehouse.updatestock(body);
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   if (responseData.response_status === "SUCCESS") {
    //   }
    // },
    async cutStock() {
      await this.validateForm();
      this.isSubmit = true;
      let responseData = [];
      let body = {
        ...this.form,
        products: this.dataItemsShow,
      };

      try {
        responseData = await whApi.warehouse.updatestock(body);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        // this.productstock = responseData.data[0];
        // await this.submittransaction();
      }
    },
    async submittransaction() {
      Swal.fire({
        icon: "question",
        title: `${this.$t("want2submit")} ${this.$t("ornot")} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let responseData = [];
          let body = {
            transaction_number: this.form.transaction_number,
            form_warehouse_id: this.form.fromWarehouseId,
            to_warehouse_id: this.form.toWarehouseId,
            products: this.dataItemsShow,
            status: this.form.status,
            remark: this.form.remark,
          };
          console.log("body", body);
          try {
            responseData = await transferApi.transfer.update(
              this.form.id,
              body
            );
          } catch (error) {
            console.log(error);
          }
          if (responseData.response_status === "SUCCESS") {
            await this.cutStock();
            // this.productstock = responseData.data[0];
            Swal.fire({
              icon: "success",
              title: "บันทึกข้อมูลสำเร็จ",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            }).then(() => {
              this.$router.push("/sale/SA4-1");
            });
          }
        }
      });
    },

    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    addItem() {
      this.dialogAddItem = true;
    },
    closeDialogAddItem() {
      this.dialogAddItem = false;
    },
  },
};
</script>
